/**
 *
 * @returns {Promise<void>}
 */
const main = async () => {
    const HEADER_BUFFER = 103;

    if (window.innerWidth < 768) {
        // just disable this on mobile...
        return;
    }

    // initialize breakpoints once the document has loaded
    document.addEventListener('DOMContentLoaded', function () {
        const navElement = document.querySelector('#product-subnav');

        // add a spacer element when the nav is pinned
        const navSpacer = document.createElement('div');
        navSpacer.style.height = navElement.clientHeight + 'px';
        navSpacer.style.display = 'none';
        navElement.parentNode.insertBefore(navSpacer, navElement.nextSibling);

        const subnavTop = navElement.offsetTop - HEADER_BUFFER;  // add buffer for main header
        const combinedHeaderBuffer = navElement.clientHeight + HEADER_BUFFER;

        // change active element based on scroll location
        const breakpoints = document.querySelectorAll('[data-nav-breakpoint]');

        let isPinned = false;

        window.addEventListener('scroll', function () {
            const scrollPos = window.scrollY;

            // pin the subnav if we scrolled past it
            if (scrollPos > subnavTop) {
                if (!isPinned) {
                    navElement.classList.add('fixed');
                    navSpacer.style.display = 'block';
                    isPinned = true;
                }

                // if subnav is pinned, determine which element should be active
                for (let i=0; i<breakpoints.length; i++) {
                    const targetEl = document.getElementById(breakpoints[i].getAttribute('href').slice(1));
                    if (targetEl !== null) {
                        const offset = targetEl.offsetTop
                        const height = targetEl.clientHeight
                        if ((offset + height - combinedHeaderBuffer) > scrollPos &&
                            (offset - combinedHeaderBuffer) <= scrollPos) {
                            breakpoints[i].classList.add('text-lochmara','underline','underline-offset-2','decoration-2');
                            breakpoints[i].classList.remove('text-shark');
                        } else {
                            breakpoints[i].classList.add('text-shark');
                            breakpoints[i].classList.remove('text-lochmara','underline','underline-offset-2','decoration-2');
                        }
                    }
                }
            } else {  // otherwise unpin it
                if (isPinned) {
                    navElement.classList.remove('fixed');
                    navSpacer.style.display = 'none';
                    isPinned = false;

                    // remove all link styling if menu is unpinned
                    for (let i=0; i<breakpoints.length; i++) {
                        breakpoints[i].classList.add('text-shark');
                        breakpoints[i].classList.remove('text-lochmara','underline','underline-offset-2','decoration-2');
                    }
                }
            }
        });

        Object.keys(breakpoints).forEach(k => {
            breakpoints[k].addEventListener('click', function (e) {
                e.preventDefault();
                const el = e.target;

                // get href link, strip off the '#'
                const hash = el.getAttribute('href').slice(1);
                const targetEl = document.getElementById(hash);
                if (targetEl !== null) {
                    // update the URL hash, but without actually modifying position
                    history.replaceState(undefined, undefined, '#'+hash);

                    // scroll to the correct location, accounting for header buffer
                    window.scroll({
                        top: targetEl.offsetTop - combinedHeaderBuffer,
                        behavior: 'smooth'
                    });
                }
            });
        });
    });
};

// Execute async function
main().then(() => {
});